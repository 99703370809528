/* Ensure the whole screen is used */
body, html {
  height: 100%;
  margin: 0;
  display: flex;
  flex-direction: column;
}

.login-page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
  background-color: #f4f4f4;
}

.login-container {
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  width: 300px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.login-form input,
.login-form button {
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  box-sizing: border-box; /* Ensure padding and border are included in the element's total width and height */
}

.login-form button {
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.login-form button:hover {
  background-color: #0056b3;
}

/* Ensure footer stays at the bottom */
footer {
  background-color: #333;
  color: white;
  text-align: center;
  padding: 10px 0;
  position: absolute;
  width: 100%;
  bottom: 0;
}
