/* Navbar.css */

.navbar {
  display: flex;
  align-items: center;
  background-image: url('./navbar_background.png');
  background-size: cover;
  background-repeat: no-repeat;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 0 20px;
  justify-content: space-between;
  position: sticky;  /* Make navbar sticky */
  top: 0;            /* Stick to the top of the viewport */
  z-index: 1000; 
}

.navbar-brand {
  font-weight: bold; /* Keep brand icon bolder */
  font-size: 1.5rem;
  color: #333; /* Dark gray for contrast */
  text-decoration: underline; /* Maintain underline */
}

.navbar-brand:hover, .navbar-brand:focus {
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5); /* Add shadow for better visibility on hover/focus */
}

.navbar-toggler {
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
}

.hamburger {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 2em;
}

.bar {
  height: 3px;
  width: 30px;
  background-color: #333; /* Dark gray for contrast */
  border-radius: 10px;
  transition: 0.3s;
}

.navbar-links {
  display: none;
  flex-direction: column;
  width: 100%;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: rgba(255, 255, 255, 0.95);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 2;
}

.navbar-links.expanded {
  display: flex;
  max-width: 100vw; /* Set maximum width to 100% of viewport width */
  overflow-x: hidden; /* Hide horizontal overflow */
}

.navbar-links a {
  color: #333;
  text-decoration: none;
  padding: 1rem 0.5rem; /* Reduce horizontal padding */
  width: 100%;
  text-align: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  background: transparent;
  transition: background-color 0.2s ease-out;
  font-weight: bold;
  -webkit-text-stroke: 0.5px rgba(255, 255, 255, 0.5);
}

.navbar-links a:hover,
.navbar-links a:active {
  background-color: rgba(0, 0, 0, 0.05);
}

.nav-link {
  color: #333; /* Dark gray for contrast */
  text-decoration: none; /* Remove underline */
  font-weight: bold; /* Make font bold */
}

.nav-link:hover, .nav-link:focus {
  color: #000; /* Darker color on hover/focus for better accessibility */
  text-decoration: none; /* Ensure underline does not reappear on hover/focus */
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5); /* Add shadow for better visibility on hover/focus */
}

.nav-link.active {
  font-weight: bold;
  border-bottom: 2px solid currentColor;
  -webkit-text-stroke: 0.5px rgba(255, 255, 255, 0.5); /* Subtle text stroke for active link */
}

@media (max-width: 768px) {
  .navbar-toggler.expanded .bar:nth-child(1) {
    transform: rotate(45deg) translate(5px, 5px);
  }

  .navbar-toggler.expanded .bar:nth-child(2) {
    opacity: 0;
  }

  .navbar-toggler.expanded .bar:nth-child(3) {
    transform: rotate(-45deg) translate(7px, -6px);
  }
}

@media (min-width: 769px) {
  .navbar-toggler {
    display: none;
  }

  .navbar-links {
    display: flex;
    position: static;
    flex-direction: row;
    justify-content: flex-end;
    background-color: transparent;
    box-shadow: none;
  }

  .navbar-links a {
    padding: 0.5rem 1rem;
    border-bottom: none;
  }

  .nav-link.active {
    font-weight: bold;
    border-bottom: 2px solid currentColor;
  }
}
