.full-page-container {
  display: flex;
  flex-direction: column;
  align-items: center; /* Center the container horizontally */
  justify-content: flex-start; /* Align the container at the top */
  min-height: 80vh; /* Ensure the container takes up the full height of the viewport */
  background-color: #fff; /* Ensure the background is white */
}

.articles-container {
  width: 80%; /* Adjust the width as needed */
  max-width: 1200px; /* Set a maximum width */
  padding: 20px;
  background-color: #f4f4f4;
  border-radius: 8px;
  margin: 20px 0; /* Add vertical margin */
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.articles-header {
  color: #333;
  margin-bottom: 20px;
  text-align: center;
}

.articles-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
}

.article-card {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  padding: 20px;
  transition: transform 0.3s, box-shadow 0.3s;
}

.article-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 8px rgba(0,0,0,0.2);
}

.article-title {
  font-size: 18px;
  margin-bottom: 10px;
  color: #333;
}

.article-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.article-button {
  background-color: #dc3545;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  padding: 5px 10px;
  transition: background-color 0.3s;
}

.article-button:hover {
  background-color: #c82333;
}

.article-link {
  color: #007bff;
  text-decoration: none;
  transition: color 0.3s;
}

.article-link:hover {
  color: #0056b3;
}
