/* Footer.css */
.footer {
  background-color: rgba(51, 51, 51, 0.8); /* Semi-transparent background */
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 40px;
  width: 100%;
  box-sizing: border-box;
  position: relative; /* Changed from fixed to relative */
  margin-top: auto; /* Push footer to the bottom of flex container */
}

.footer-section {
  flex: 1;
  display: flex;
  justify-content: center;
}

.footer-section:first-child {
  justify-content: flex-start;
}

.footer-section:last-child {
  justify-content: flex-end;
}

.footer-link {
  color: white;
  text-decoration: none;
  font-weight: bold;
}

.footer-link:hover {
  text-decoration: underline;
}

.footer-text {
  font-weight: bold;
}

@media (max-width: 768px) {
  .footer {
    flex-direction: column;
    align-items: flex-start;
    padding: 20px;
  }

  .footer-section {
    justify-content: flex-start;
    margin-bottom: 10px;
  }

  .footer-section:last-child {
    margin-bottom: 0;
  }
}

/* Add these new styles */
#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Ensure the root container takes at least full viewport height */
}

main {
  flex: 1; /* Allow main content to grow and push footer down */
}

