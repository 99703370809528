/* Article.css */
:root {
  --font-size-base: 16px;
  --color-text: #333333;
  --color-background: #ffffff;
  --color-text-dark: #e0e0e0;
  --color-background-dark: #1e1e1e;
}

html, body {
  margin: 0;
  padding: 0;
  min-height: 100vh;
}

body.dark-mode {
  background-color: var(--color-background-dark);
  color: var(--color-text-dark);
}

#articleContainer {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  min-height: 100vh;
  background-color: var(--color-background);
  color: var(--color-text);
  transition: background-color 0.3s, color 0.3s;
}

#articleHeader {
  width: 100%;
  text-align: left;
}

#articleCategory {
  font-size: 1rem;
  margin-bottom: 5px;
}

#articleTitle {
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 20px;
  line-height: 1.2;
}

#articleAuthor {
  font-size: calc(var(--font-size-base) * 0.9);
  margin-bottom: 20px;
  color: #555;
}

#articleImage {
  width: 100%;
  max-width: 800px;
  margin: 20px auto;
  aspect-ratio: 16 / 9;
  overflow: hidden;
  position: relative;
}

#articleImage img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  position: absolute;
  top: 0;
  left: 0;
}

#readingTime {
  font-size: 0.9rem;
  color: #666;
  margin-bottom: 20px;
}

.article-content {
  text-align: left;
  font-size: var(--font-size-base);
  line-height: 1.6;
  width: 100%;
  color: var(--color-text);
}

.article-content h1,
.article-content h2,
.article-content h3,
.article-content h4,
.article-content h5,
.article-content h6 {
  margin-top: 1.5em;
  margin-bottom: 0.5em;
  line-height: 1.2;
}

.article-content p {
  margin-bottom: 1em;
}

.article-content a {
  color: #0066cc;
  text-decoration: none;
}

.article-content a:hover {
  text-decoration: underline;
}

.article-content ul,
.article-content ol {
  margin-bottom: 1em;
  padding-left: 1.5em;
}

.article-content blockquote {
  border-left: 4px solid #ccc;
  margin-left: 0;
  padding-left: 1em;
  color: #666;
}

/* Navigation styles */
.navigation-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 40px;
  margin-bottom: 40px;
  gap: 24px;
}

.navigation-link {
  flex: 1;
  padding: 20px;
  text-decoration: none;
  color: inherit;
  background-color: var(--color-background);
  border-radius: 12px;
  max-width: 45%;
  position: relative;
  border: 1px solid #eaeaea;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.04);
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

.navigation-link:hover {
  transform: translateY(-2px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.08);
  border-color: #ddd;
}

.navigation-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}

.navigation-content span {
  display: block;
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-size: 1rem;
}

.navigation-content span:first-child {
  font-weight: 500;
  margin-bottom: 8px;
  color: inherit;
}

.navigation-content span:last-child {
  font-size: 0.85rem;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  font-weight: 500;
  opacity: 0.7;
}

.dark-mode-toggle {
  background: none;
  border: 1px solid #ccc;
  color: inherit;
  cursor: pointer;
  font-size: 0.9rem;
  padding: 5px 10px;
  margin-top: 10px;
  border-radius: 5px;
}

/* Dark mode styles */
body.dark-mode #articleContainer,
body.dark-mode .article-content {
  background-color: var(--color-background-dark);
  color: var(--color-text-dark);
}

body.dark-mode #articleTitle {
  color: #ffffff;
}

body.dark-mode #articleAuthor,
body.dark-mode #articleCategory,
body.dark-mode #readingTime {
  color: #b0b0b0;
}

body.dark-mode .article-content h1,
body.dark-mode .article-content h2,
body.dark-mode .article-content h3,
body.dark-mode .article-content h4,
body.dark-mode .article-content h5,
body.dark-mode .article-content h6 {
  color: #ffffff;
}

body.dark-mode .article-content p,
body.dark-mode .article-content ul,
body.dark-mode .article-content ol,
body.dark-mode .article-content span {
  color: var(--color-text-dark);
}

body.dark-mode .article-content a {
  color: #4a9eff;
}

body.dark-mode .article-content blockquote {
  border-left-color: #666;
  color: #b0b0b0;
}

body.dark-mode .navigation-link {
  background-color: #2c2c2c;
  border-color: #404040;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  color: var(--color-text-dark);
}

body.dark-mode .navigation-link:hover {
  border-color: #505050;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
  background-color: #333333;
}

body.dark-mode .navigation-content span:first-child {
  color: var(--color-text-dark);
}

body.dark-mode .navigation-content span:last-child {
  opacity: 0.7;
}

body.dark-mode .dark-mode-toggle {
  border-color: #e0e0e0;
}

/* Ensure the article container takes up the full height */
#articleContainer {
  min-height: 100vh;
}

/* Media Queries */
@media (max-width: 768px) {
  #articleContainer {
    width: 90%;
    padding: 15px;
  }

  #articleTitle {
    font-size: 2rem;
  }

  #articleAuthor {
    font-size: 0.8rem;
  }

  #articleImage {
    width: 100%;
    aspect-ratio: 4 / 3;
  }

  .navigation-container {
    flex-direction: column;
    align-items: stretch;
  }

  .navigation-link {
    max-width: 100%;
    margin-bottom: 10px;
  }

  .navigation-content span {
    font-size: 0.9rem;
  }
}

@media (min-width: 769px) {
  :root {
    --font-size-base: 18px;
  }

  .navigation-link {
    font-size: 1.5rem;
  }
}

