/* src/components/About.css */
.about-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background-color: #e6f3ff; /* Light blue background similar to your homepage */
}

.about-title {
  font-size: 2.5rem;
  color: #333;
  text-align: center;
  margin-bottom: 20px;
}

.about-content {
  font-size: 1.1rem;
  line-height: 1.6;
  color: #444;
}

.about-content p {
  margin-bottom: 15px;
}

.about-content ul {
  margin-left: 20px;
  margin-bottom: 15px;
}

.about-content li {
  margin-bottom: 10px;
}

@media (max-width: 768px) {
  .about-container {
    padding: 15px;
  }

  .about-title {
    font-size: 2rem;
  }

  .about-content {
    font-size: 1rem;
  }
}
