.full-page-container {
  display: flex;
  flex-direction: column;
  align-items: center; /* Center the container horizontally */
  justify-content: center; /* Center the container vertically */
  min-height: 80vh; /* Ensure the container takes up the full height of the viewport */
  background-color: #fff; /* Ensure the background is white */
}

.create-article-container {
    padding: 20px;
    background-color: #f4f4f4;
    border-radius: 8px;
    margin: 20px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  }
  
  .create-article-form input,
  .create-article-form select,
  .create-article-form textarea {
    width: 100%;
    padding: 8px;
    margin: 10px 0;
    box-sizing: border-box;
  }
  
  .create-article-form button {
    width: 100%;
    padding: 10px;
    background-color: #28a745;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .create-article-form button:hover {
    background-color: #218838;
  }