/* Add styles for the full-page container */
.full-page-container {
  display: flex;
  flex-direction: column;
  align-items: center; /* Center the dashboard container horizontally */
  justify-content: flex-start; /* Align the dashboard container at the top */
  min-height: 80vh;
  background-color: #fff; /* Ensure the background is white */
}

.dashboard-container {
  width: 80%; /* Adjust the width as needed */
  max-width: 1200px; /* Set a maximum width */
  padding: 20px;
  background-color: #f4f4f4;
  border-radius: 8px;
  margin: 20px 0; /* Add vertical margin */
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.dashboard-header {
  color: #333;
  margin-bottom: 20px;
}

.dashboard-list {
  list-style-type: none;
  padding: 0;
}

.dashboard-list li {
  padding: 10px 0; /* Add vertical padding */
  border-bottom: 1px solid #ccc;
}

.dashboard-list li:last-child {
  border-bottom: none;
}

/* Modern styling for hyperlinks and buttons */
.dashboard-list li a,
.dashboard-list li button {
  color: #007bff;
  text-decoration: none;
  padding: 10px;
  display: inline-block;
  transition: background-color 0.3s, color 0.3s;
  background: none;
  border: none;
  cursor: pointer;
  text-align: left;
  font-size: 16px; /* Ensure consistent font size */
}

.dashboard-list li a:hover,
.dashboard-list li button:hover {
  background-color: #007bff;
  color: white;
  border-radius: 4px;
  width: calc(100% - 20px); /* Adjust width to fit within padding */
}
  
