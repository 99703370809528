/* ArticlePreview.css */
.articlePreview {
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  transition: box-shadow 0.3s ease-in-out, transform 0.3s ease-in-out;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.articlePreview:hover {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
  transform: translateY(-4px);
}

.articlePreviewLarge {
  grid-column: span 3;
}

.articleImageContainer {
  flex: 1;
  overflow: hidden;
  position: relative;
}

.articleImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease-in-out;
}

.articlePreview:hover .articleImage {
  transform: scale(1.05);
}

/* Styles for homepage article previews */
.articlePreview:not(.categoryView) .articleInfo {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 24px;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.8) 0%, transparent 100%);
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  transition: background 0.3s ease-in-out;
  height: 100%;
  box-sizing: border-box;
}

.articlePreview:not(.categoryView):hover .articleInfo {
  background: linear-gradient(to top, rgba(0, 0, 0, 0.9) 0%, transparent 100%);
}

.articlePreview:not(.categoryView) .articleTitle {
  font-size: 1.5em;
  color: #fff;
  font-weight: 600;
  margin-bottom: 4px;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
}

.articlePreview:not(.categoryView) .articleExcerpt {
  color: #ddd;
  margin-bottom: 12px;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
}

.articlePreview:not(.categoryView) .readMoreLink {
  color: #fff;
  text-decoration: none;
  font-weight: 500;
  transition: color 0.3s ease-in-out;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
}

.articlePreview:not(.categoryView) .readMoreLink:hover {
  color: #ccc;
}

.articlePreview:not(.categoryView) .articleAuthorDate {
  color: #fff;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
  font-size: 0.8em;
  display: block;
  margin-top: 4px;
  margin-bottom: 8px;
}

/* Styles for category and all views */
.articlePreview.categoryView {
  flex-direction: row;
  height: 150px;
}

.articlePreview.categoryView .articleImageContainer {
  width: 30%;
  height: 100%;
}

.articlePreview.categoryView .articleInfo {
  width: 70%;
  position: relative;
  background: none;
  color: #333;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1rem;
}

.articlePreview.categoryView .articleTitle {
  font-size: 1.1em;
  margin-bottom: 0.3rem;
  max-height: 2.6em;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  line-clamp: 2;
}

.articlePreview.categoryView .articleExcerpt {
  font-size: 0.9em;
  margin-bottom: 0.3rem;
  max-height: 3.6em;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  line-clamp: 3;
}

.articlePreview.categoryView .articleAuthorDate {
  font-size: 0.8em;
  margin-bottom: 0.3rem;
}

.articlePreview.categoryView .readMoreLink {
  align-self: flex-start;
  margin-top: auto;
  color: #0066cc;
  text-decoration: none;
}

.articlePreview.categoryView .readMoreLink:hover {
  text-decoration: underline;
}

@media (max-width: 768px) {
  .articlePreview,
  .articlePreview.categoryView {
    flex-direction: column;
    height: auto;
  }
  .articleImageContainer,
  .articlePreview.categoryView .articleImageContainer,
  .articleInfo,
  .articlePreview.categoryView .articleInfo {
    width: 100%;
  }
  .articleInfo {
    position: relative;
    background: #fff;
    color: #333;
  }
  .articleTitle,
  .articleExcerpt,
  .articleAuthorDate,
  .readMoreLink {
    color: #333;
    text-shadow: none;
  }
}
