/* HeroSection.css */

.hero {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50vh;
  color: #ffffff;
  text-align: center;
  padding: 0 15px;
  position: relative;
}

.hero-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.hero-content {
  max-width: 800px;
  padding: 40px;
  z-index: 1;
}

.hero h1 {
  font-size: calc(2rem + 1.5vw); /* Responsive font size */
  font-weight: 700;
  margin-bottom: 1rem;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  line-height: 1.2; /* Adjust line height for better readability */
}

.hero p {
  font-size: calc(1rem + 0.5vw); /* Responsive font size */
  margin-bottom: 2rem;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
  line-height: 1.4; /* Adjust line height for better readability */
}

.hero-btn {
  padding: 1.2rem 2.5rem;
  font-size: 1.2rem;
  color: #ffffff;
  background-color: #32a852;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .hero {
    height: 40vh;
  }
}

/* Button hover effect */
.hero-btn:hover {
  background-color: #0056b3;
  transform: scale(1.05);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}