/* Existing styles for grid layout */
.articles-list {
  display: grid;
  grid-template-columns: repeat(8, 1fr); /* 8-column grid */
  grid-gap: 1rem;
  padding: 1rem;
  width: 100%; /* Ensure the grid takes full width */
  box-sizing: border-box;
}

.articles-list > .articlePreviewList:first-child {
  grid-column: span 4; /* First large article takes 4 columns */
  grid-row: span 2; /* Makes it taller, spanning two rows */
}

.articles-list > .articlePreviewList:not(:first-child) {
  grid-column: span 2; /* Smaller articles take 2 columns */
  grid-row: span 1; /* Half the height of the large article */
}

/* Responsive design for smaller screens */
@media (max-width: 1024px) {
  .articles-list {
    grid-template-columns: repeat(4, 1fr); /* Adjust grid for medium screens */
  }
  .articles-list > .articlePreviewList:first-child,
  .articles-list > .articlePreviewList:not(:first-child) {
    grid-column: span 2; /* Each article spans all columns on medium screens */
    grid-row: span 1; /* Normalize the height */
  }
}

@media (max-width: 768px) {
  .articles-list,
  .articles-list.single-column {
    display: flex;
    flex-direction: column;
  }
  .articles-list > .articlePreviewList:first-child,
  .articles-list > .articlePreviewList:not(:first-child),
  .articles-list.single-column > .articlePreviewList {
    width: 100%;
  }
  .articlePreview,
  .articles-list.single-column .articlePreview {
    flex-direction: column;
    height: auto;
  }
  .articleImageContainer,
  .articles-list.single-column .articleImageContainer,
  .articleInfo,
  .articles-list.single-column .articleInfo {
    width: 100%;
  }
}

/* Styles for single-column layout in category and all views */
.articles-list.single-column {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 1rem; /* Reduce gap between articles */
}

.articles-list.single-column > .articlePreviewList {
  width: 100%;
  margin-bottom: 0; /* Remove bottom margin */
}

.articles-list.single-column .articlePreview {
  display: flex;
  flex-direction: row;
  height: 200px; /* Adjust this value as needed */
}

.articles-list.single-column .articleImageContainer {
  width: 30%;
  height: 100%;
}

.articles-list.single-column .articleInfo {
  width: 70%;
  position: relative;
  background: none;
  color: #333;
  padding: 1rem;
}

/* Add this new style */
.articlePreviewList {
  margin: 0;
  padding: 0;
}